import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import CenterDiv from '../elements/CenterDiv'
import { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

const ContactDiv = styled.div`
     margin-top: 3%;
    margin-bottom: 3%;
`;
const StyledInput = styled.input`
    border: 0;
    outline: 0;
    border-bottom: 1px solid grey;
    width: 100%;
`;
const Btn = styled.button`
    padding: 0.5% 3% 0.5% 3%;
     /* background-color: ${(props) => props.color}; */
    color: white;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     /* float: right; */
    padding-top: 1%;
    padding-bottom: 1%;

`;
const Contact = () => {
    const data = useStaticQuery(graphql`
    query {
        contentBlock: allContentfulBlocks(
          filter: { path: { eq: "/home" } }
          sort: { fields: orderId }
        ) {
          edges {
            node {
              body {
                childContentfulRichText {
                  html
                }
              }
            }
          }
        }
        button: allContentfulButtons(
            filter: { path: { eq: "/home" } }
            sort: { fields: orderId }
          ) {
            edges {
              node {
                orderId
                text
              }
            }
          }
      }
`)
    const contactButton = data.button.edges[2].node.text
    const contactBlock = data.contentBlock.edges[6].node.body.childContentfulRichText.html

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const handleEmailChange = event => {
        setEmail(event.currentTarget.value);
    };

    const handleNameChange = event => {
        setName(event.currentTarget.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const templateId = 'contact_us';
        setName('');
        setEmail('');

        sendFeedback(templateId, { message_html: "", from_name: name, reply_to: email, });
    }

    function sendFeedback(templateId, variables) {
        emailjs.send(
            'gmail', templateId,
            variables, 'user_aVNdeoj10gPzyYDyQwoPV'
        ).then(res => {
            console.log('Email successfully sent!');
            Swal.fire(
                'Form submitted!',
                'We will get back to you soon',
                'success'
            )
        })
            .catch(err => {
                console.error('Oh well, you failed. Here some thoughts on the error that occurred:', err);
                Swal.fire(
                    'Opss...',
                    'Looks like you missed some thing, try submitting another form',
                    'error'
                )
            })
    }
    return (
        <ContactDiv className="container">
            <Row style={{ padding: "5%" }}>
                <Col sm={12} md={5}>
                    <div className="avenir-roman body-font-size" dangerouslySetInnerHTML={{ __html: contactBlock }}></div>
                </Col>
                <Col sm={12} md={6} style={{ marginLeft: "auto" }}>
                    <form onSubmit={handleSubmit}>
                        <p><StyledInput type="text" placeholder="Name" value={name} name="name" required onChange={handleNameChange} /></p>
                        <p><StyledInput type="email" placeholder="Email" value={email} name="email" required onChange={handleEmailChange} /></p>
                        <button style={{ float: "right", background: "#92baca", height: "37px", fontFamily: "avenir-black" }} type="submit" className="ml-auto mybtn"><span dangerouslySetInnerHTML={{ __html: contactButton }}></span></button>
                        {/* <Btn style={{backgroundColor:"#92baca", float:"right"}} color="#92baca" type="submit" class="btn tn-sm avenir-black">GET IN TOUCH</Btn> */}
                    </form>
                </Col>
            </Row>
        </ContactDiv>
    )
}

export default Contact
