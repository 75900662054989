import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import CenterText from '../elements/CenterText'
import CenterDiv from '../elements/CenterDiv'
import styled from 'styled-components'
import VHCenter from "../elements/VHCenter"
import { Row, Col } from 'react-bootstrap'

import Shift from '../../assets/shift.png'
import Move from '../../assets/move.png'
import Change from '../../assets/change.png'


const ColDiv = styled(Col)`
    background-color: ${(props) => props.color};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 250px;

`;
const Div = styled.div`
    width: 90%;
    height: 200px; 
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: ${(props) => props.color};
    margin: auto ;
    @media (max-width: 991px) {
    width: 100%;
  }
    @media (max-width: 767px) {
    width: 60%;
  }
`;
export default function Objective() {

    const data = useStaticQuery(graphql`
            query  {
                TitleDivQuery: allContentfulTitleDiv(
                    filter: { path: { eq: "/home" }}, 
                    sort: { fields: orderId }
                    )
                {
                    edges {
                    node {
                        orderId
                        header 
                    }
                    }
                }
                contentBlock: allContentfulBlocks(
                    filter: { path: { eq: "/home" } }
                    sort: { fields: orderId }
                  ) {
                    edges {
                      node {
                        width
                        height
                        image{
                            fluid{
                                src
                            }
                        }
                        body {
                          childContentfulRichText {
                            html
                          }
                        }
                      }
                    }
                  }
        }
      `)
    const title = data.TitleDivQuery.edges[4].node.header
    const objectiveBlock = data.contentBlock.edges[5].node.body.childContentfulRichText.html
    const shiftText = data.contentBlock.edges[7].node.body.childContentfulRichText.html
    const shiftImg = data.contentBlock.edges[7].node.image.fluid.src
    const shiftHeight = data.contentBlock.edges[7].node.height
    const shiftWidth = data.contentBlock.edges[7].node.width

    const moveText = data.contentBlock.edges[8].node.body.childContentfulRichText.html
    const moveImg = data.contentBlock.edges[8].node.image.fluid.src
    const moveHeight = data.contentBlock.edges[8].node.height
    const moveWidth = data.contentBlock.edges[8].node.width

    const changeText = data.contentBlock.edges[9].node.body.childContentfulRichText.html
    const changeImg = data.contentBlock.edges[9].node.image.fluid.src
    const changeHeight = data.contentBlock.edges[9].node.height
    const changeWidth = data.contentBlock.edges[9].node.width

    return (
        <div className="container">
            <div className="container" style={{ marginTop: "5%" }}>
                <CenterDiv fullWidth className="container">
                    <div className="avenir-roman font-size" dangerouslySetInnerHTML={{ __html: objectiveBlock }}></div>
                </CenterDiv>
            </div>
            <div className="row" style={{ marginTop: "8%" }}>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center" style={{ marginBottom: "7%" }}>
                    <Div color="#ed6c2b" >
                        <VHCenter>
                            <div className="" style={{ width: "200px", height: "100px", margin: "auto" }}>
                                <VHCenter><img src={shiftImg} alt="shift" width={shiftWidth} height={shiftHeight} style={{ margin: "auto" }} /></VHCenter>
                            </div>
                            <div className="m-3 avenir-medium" style={{ color: "white", fontSize: "19px" }}>
                                <span dangerouslySetInnerHTML={{ __html: shiftText }}></span>
                            </div>
                        </VHCenter>
                    </Div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center" style={{ marginBottom: "7%" }}>
                    <Div color="#feb600" >
                        <VHCenter>
                            <div className="" style={{ width: "200px", height: "100px", margin: "auto" }}>
                                <VHCenter><img src={moveImg} alt="move" width={moveWidth} height={moveHeight} style={{ margin: "auto" }} /></VHCenter>
                            </div>
                            <div className="m-3 avenir-medium" style={{ color: "white", fontSize: "19px" }}>
                                <span dangerouslySetInnerHTML={{ __html: moveText }}></span>
                            </div>
                        </VHCenter>
                    </Div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center" style={{ marginBottom: "7%" }}>
                    <Div color="#84b8cd" >
                        <VHCenter>
                            <div className="" style={{ width: "200px", height: "100px", margin: "auto", paddingTop: "21px" }}>
                                <VHCenter ><img src={changeImg} alt="change" width='172px' height='59px' style={{ margin: "auto" }} /></VHCenter>
                            </div>
                            <div className="m-3 avenir-medium" style={{ color: "white", fontSize: "19px" }}>
                                <span dangerouslySetInnerHTML={{ __html: changeText }}></span>
                            </div>
                        </VHCenter>
                    </Div>
                </div>
                <div className="container text-center avenir-black" style={{ color: "#6e6e6e", marginTop: "7%" }}>
                    <h2><div dangerouslySetInnerHTML={{ __html: title }}></div></h2>
                </div>
            </div >

        </div >
    )
}
