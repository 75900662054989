import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components"
import slider1 from "../../assets/slider/slider1-min.jpg"
import slider2 from "../../assets/slider/slider2-min.jpg"
import slider3 from "../../assets/slider/slider3-min.jpg"
import slider4 from "../../assets/slider/slider4-min.jpg"
// import flag from "../../assets/slider/flag.gif"
// import circles from '../../assets/slider/circles.gif'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import "./carousel.css"

const Caption = styled.div`
    z-index: 0;
`;

const Carousel = () => {

    const data = useStaticQuery(graphql`
        query sliderTextQuery {
            slider: allContentfulSlider(sort: { fields: orderId }) {
            edges {
                node {
                orderId
                sliderText
                sliderImg{
                    fluid(quality: 100){
                        src
                    }
                }
                }
            }
            }
        } 
      
    `)

    const text1 = data.slider.edges[0].node.sliderText
    const text2 = data.slider.edges[1].node.sliderText
    const text3 = data.slider.edges[2].node.sliderText
    const text4 = data.slider.edges[3].node.sliderText

    const sliderImg1 = data.slider.edges[0].node.sliderImg.fluid.src
    const sliderImg2 = data.slider.edges[1].node.sliderImg.fluid.src
    const sliderImg3 = data.slider.edges[2].node.sliderImg.fluid.src
    const sliderImg4 = data.slider.edges[3].node.sliderImg.fluid.src
    const flag = data.slider.edges[4].node.sliderImg.fluid.src
    const circles = data.slider.edges[5].node.sliderImg.fluid.src



    return (
        <div>
            <div id="carouselExampleIndicators" data-interval="10000" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators" style={{ zIndex: "1" }}>
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src={sliderImg1} alt="First slide" />
                        <div class="carousel-caption center" style={{ zIndex: "0" }}>
                            <div className="div-size">
                                <h2 className="sm-heading avenir-heavy"><div dangerouslySetInnerHTML={{ __html: text1 }}></div></h2>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={sliderImg2} alt="Second slide" />
                        <div class="carousel-caption center" style={{ zIndex: "0" }}>
                            <div className="div-size">
                                <h2 className="sm-heading avenir-heavy"><div dangerouslySetInnerHTML={{ __html: text2 }}></div></h2>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img className="d-block w-100" src={sliderImg3} alt="Third slide" />
                        <div className="carousel-caption center" style={{ zIndex: "0" }}>
                            <div className="div-size">
                                <h1 className="sm-heading avenir-heavy"><div dangerouslySetInnerHTML={{ __html: text3 }}></div></h1>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={sliderImg4} alt="Fourth slide" />
                        <div class="carousel-caption center" style={{ zIndex: "0" }}>
                            <div className="div-size">
                                <h2 className="sm-heading avenir-heavy"><div dangerouslySetInnerHTML={{ __html: text4 }}></div></h2>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={flag} alt="Fifth slide" />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src={circles} alt="Sixth slide" />
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="" aria-hidden="true"><FaChevronCircleLeft style={{ color: "#c7c7c7", fontSize: "40px" }} /></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="" aria-hidden="true"><FaChevronCircleRight style={{ color: "#c7c7c7", fontSize: "40px" }} /></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    )
}

export default Carousel


