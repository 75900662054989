import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Intro from "../components/landingPage/Intro"
import Help from "../components/landingPage/Help"
import About from "../components/landingPage/About"
import Objective from "../components/landingPage/Objective"
import ListingHelp from '../components/landingPage/ListingHelp'
import Contact from "../components/landingPage/Contact"
import CenterText from "../components/elements/CenterText"
import SEO from "../components/seo"
import Carousel from "../components/landingPage/Carousel";

const IndexPage = ({ data }) => {
  const title = data.TitleDivQuery.edges[0].node.header
  const helpBlock = data.contentBlock.edges[1].node.body.childContentfulRichText.html

  return (
    <Layout>
      <SEO title="Home" />
      <Carousel />
      <Intro />
      <CenterText>
        <h1 className="p-3 avenir-black" style={{ color: "#6e6e6e" }}>
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </h1>
      </CenterText>
      <CenterText className="avenir-roman font-size container">
        <div dangerouslySetInnerHTML={{ __html: helpBlock }}></div>
      </CenterText>
      <ListingHelp path="/home" />
      <About />
      <Objective />
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query  {
  TitleDivQuery: allContentfulTitleDiv(
    filter: { path: { eq: "/home" }}, 
    sort: { fields: orderId }
    )
  {
    edges {
      node {
        orderId
        header 
      }
    }
  }
  contentBlock: allContentfulBlocks(
    filter: { path: { eq: "/home" } }
    sort: { fields: orderId }
  ) {
    edges {
      node {
        body {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  
}
`
