import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Flag from "../../assets/flag.jpg"
import styled from 'styled-components'
import CenterText from '../elements/CenterText'

const IntroDiv = styled.div`
     margin-top: 5%;
    margin-bottom: 5%;
`;

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
        contentBlock: allContentfulBlocks(
          filter: { path: { eq: "/home" } }
          sort: { fields: orderId }
        ) {
          edges {
            node {
              body {
                childContentfulRichText {
                  html
                }
              }
              image{
                fluid{
                  src
                }
              }
            }
          }
        }
      }
`)
  const introBlock = data.contentBlock.edges[0].node.body.childContentfulRichText.html
  const introImg = data.contentBlock.edges[0].node.image.fluid.src

  return (
    <IntroDiv className="container" style={{ marginTop: "5%", marginBottom: "5%" }}>
      <div className="container">
        <CenterText className="avenir-roman font-size">
          <div dangerouslySetInnerHTML={{ __html: introBlock }}></div>
        </CenterText>
        <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: "8%" }} >
          <img className="flag" src={introImg} alt="happiness ftt flag" />
        </div>
      </div>
    </IntroDiv >
  )
}

export default Intro
