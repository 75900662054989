import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaAngleRight } from "react-icons/fa"
import CenterText from "../elements/CenterText"
import VHCenter from "../elements/VHCenter"
import CenterDiv from "../elements/CenterDiv"

import circles from "../../assets/circles.jpg"
import plane from "../../assets/plane.png"
import vectorHuman from "../../assets/vector-human.png"
import rightArrow from "../../assets/right-arrow.png"
import logo from "../../assets/logo.png"
import model from "../../assets/model.png"

const About = () => {

    const data = useStaticQuery(graphql`
    query {
      TitleDivQuery: allContentfulTitleDiv(
        filter: { path: { eq: "/home" } }
        sort: { fields: orderId }
      ) {
        edges {
          node {
            orderId
            header
          }
        }
      }
      contentBlock: allContentfulBlocks(
        filter: { path: { eq: "/home" } }
        sort: { fields: orderId }
      ) {
        edges {
          node {
            width
            height
            body {
              childContentfulRichText {
                html
              }
            }
            image {
              fluid {
                src
              }
            }
          }
        }
      }
      button: allContentfulButtons(
        filter: { path: { eq: "/home" } }
        sort: { fields: orderId }
      ) {
        edges {
          node {
            orderId
            text
          }
        }
      }
    }
  `)
    const firstTitle = data.TitleDivQuery.edges[1].node.header
    const secondTitle = data.TitleDivQuery.edges[2].node.header
    const thirdTitle = data.TitleDivQuery.edges[3].node.header

    const aboutBlock =
        data.contentBlock.edges[2].node.body.childContentfulRichText.html
    const aboutImg = data.contentBlock.edges[2].node.image.fluid.src

    const individualBlock =
        data.contentBlock.edges[3].node.body.childContentfulRichText.html
    const indCount = 232


    const individualImg = data.contentBlock.edges[3].node.image.fluid.src
    const individualWidth = data.contentBlock.edges[3].node.width
    const individualHeight = data.contentBlock.edges[3].node.height

    const corporatesBlock =
        data.contentBlock.edges[4].node.body.childContentfulRichText.html
    const corpCount = 203

    const corporatesImg = data.contentBlock.edges[4].node.image.fluid.src
    const corporatesWidth = data.contentBlock.edges[4].node.width
    const corporatesHeight = data.contentBlock.edges[4].node.height

    const corporatesButton = data.button.edges[0].node.text
    const individualButton = data.button.edges[1].node.text

    return (
        <CenterText className="container">
            <CenterDiv className=" container center mb-5 mt-5 pt-5">
                <div className="" style={{ height: "150px" }}>
                    <VHCenter>
                        <img className="model-logo" src={logo} />
                    </VHCenter>
                </div>
                <div className="" style={{ height: "150px" }}>
                    <VHCenter>
                        <img className="model" src={model} />
                    </VHCenter>
                </div>
            </CenterDiv>
            <CenterDiv
                myWidth="60%"
                smWidth="100%"
                className=" container "
                style={{ marginBottom: "10%" }}
            >
                <h3 className="center avenir-medium" style={{ color: "#6e6e6e" }}>
                    <div dangerouslySetInnerHTML={{ __html: firstTitle }}></div>
                </h3>
            </CenterDiv>
            <div className="row center">
                <div className="col-md-4 ">
                    <p>
                        <img className="w-100" src={aboutImg} alt="about happiness ftt" />
                    </p>
                </div>
                <div className="m-auto col-md-6 text-left avenir-roman body-font-size" >
                    <div
                        style={{ color: "#6e6e6e", lineHeight: "200%" }}
                        className="avenir-roman text-justify"
                    >
                        <div dangerouslySetInnerHTML={{ __html: aboutBlock }}></div>
                    </div>
                </div>
            </div>
            <div className="row center pb-3" style={{ marginTop: "5%" }}>
                <div className="col-sm-12 col-md-5">
                    <div className="custom-height">
                        <div
                            className="text-left"
                            style={{ height: "150px", margin: "auto" }}
                        >
                            <VHCenter>
                                <img
                                    width={individualWidth}
                                    height={individualHeight}
                                    src={individualImg}
                                    alt="plane"
                                />
                            </VHCenter>
                        </div>
                        <h3
                            className="py-4  avenir-black text-left"
                            style={{ color: "#ef7b41" }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: secondTitle }}></div>
                        </h3>
                        <div className="" style={{ height: "200px" }}>
                            <div className="avenir-medium text-justify body-font-size para">
                                {individualBlock.length > indCount ? (
                                    <div dangerouslySetInnerHTML={{ __html: individualBlock.substring(0, indCount) + "..." }}>
                                    </div>
                                ) : (
                                        <div dangerouslySetInnerHTML={{ __html: individualBlock }}></div>
                                    )}

                            </div>
                        </div>
                    </div>
                    <div className="text-left">
                        <Link to="/individuals">
                            <button
                                style={{ border: "1px solid black", borderRadius: "0px" }}
                                type="button"
                                className="btn btn-outline-dark mb-5"
                            >
                                <span
                                    dangerouslySetInnerHTML={{ __html: individualButton }}
                                ></span>
                                <FaAngleRight />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-md-1 center">
                    <span className="vl"></span>
                </div>
                <div className="col-sm-12 col-md-5">
                    <div className="custom-height">
                        <div
                            className="text-right"
                            style={{ height: "150px", margin: "auto" }}
                        >
                            <VHCenter>
                                <img
                                    width={corporatesWidth}
                                    height={corporatesHeight}
                                    src={corporatesImg}
                                    alt="Sheild"
                                />
                            </VHCenter>
                        </div>
                        <h3
                            className="py-4 avenir-black text-right"
                            style={{ color: "#feb600" }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: thirdTitle }}></div>
                        </h3>
                        <div className="avenir-medium text-justify body-font-size">
                            {corporatesBlock.length > corpCount ? (
                                <div dangerouslySetInnerHTML={{ __html: corporatesBlock.substring(0, corpCount) + '...' }}>
                                </div>
                            ) : (
                                    <div dangerouslySetInnerHTML={{ __html: corporatesBlock }}></div>
                                )}
                        </div>
                    </div>
                    <div className="text-right">
                        <Link to="/corporates">
                            <button
                                style={{ borderRadius: "0px", fontFamily: "avenir-medium" }}
                                type="button"
                                class="btn btn-outline-dark"
                            >
                                <span
                                    dangerouslySetInnerHTML={{ __html: corporatesButton }}
                                ></span>
                                <FaAngleRight />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <hr style={{ backgroundColor: "grey" }} className="w-100" />
        </CenterText>
    )
}

export default About