// This component lists the happiness ftt Values section on Ourstory page and HappyChildren Section on kids page

import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Help from './Help';


const ListingComponent = ({ path }) => {

  const data = useStaticQuery(graphql`
    query  {
     
      help: allContentfulValues(
        filter: { path: { eq: "/home" } },
        sort: { fields: orderId }
      )
      {
        edges {
          node {
            heading
            orderId
            icon{
              fluid{
                src
              }
            }
            width,
            height,
            text{
              childContentfulRichText{
                html
              }
            }
          }
        }
      }
      
      }
    `)
  return (
    <div>
      <div className="container">
        <div style={{ paddingBottom: "10%" }}>
          <div className="row center mb-lg-2 pb-lg-0 mb-5 pb-5" >
            {
              path == "/home" ?
                data.help.edges.map((edge) => {
                  return (
                    <Help
                      path="/home"
                      heading={edge.node.heading}
                      width={edge.node.width}
                      height={edge.node.height}
                      icon={edge.node.icon.fluid.src}
                      text={edge.node.text.childContentfulRichText.html}
                    />
                  )
                })
                :
                ""
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingComponent


